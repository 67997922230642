@use './variables';

// Orbitron
@font-face {
  font-family: $orbitron;
  font-weight: 400;
  src: url('../assets/fonts/Orbitron/Orbitron-Regular.ttf') format('truetype');
}

@font-face {
  font-family: $orbitron;
  font-weight: 500;
  src: url('../assets/fonts/Orbitron/Orbitron-Medium.ttf') format('truetype');
}

@font-face {
  font-family: $orbitron;
  font-weight: 600;
  src: url('../assets/fonts/Orbitron/Orbitron-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: $orbitron;
  font-weight: 700;
  src: url('../assets/fonts/Orbitron/Orbitron-Bold.ttf') format('truetype');
}

@font-face {
  font-family: $orbitron;
  font-weight: 800;
  src: url('../assets/fonts/Orbitron/Orbitron-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: $orbitron;
  font-weight: 900;
  src: url('../assets/fonts/Orbitron/Orbitron-Black.ttf') format('truetype');
}

// Comfortaa
@font-face {
  font-family: $comfortaa;
  font-weight: 300;
  src: url('../assets/fonts/Comfortaa/Comfortaa-Light.ttf') format('truetype');
}

@font-face {
  font-family: $comfortaa;
  font-weight: 400;
  src: url('../assets/fonts/Comfortaa/Comfortaa-Regular.ttf') format('truetype');
}

@font-face {
  font-family: $comfortaa;
  font-weight: 500;
  src: url('../assets/fonts/Comfortaa/Comfortaa-Medium.ttf') format('truetype');
}

@font-face {
  font-family: $comfortaa;
  font-weight: 600;
  src: url('../assets/fonts/Comfortaa/Comfortaa-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: $comfortaa;
  font-weight: 700;
  src: url('../assets/fonts/Comfortaa/Comfortaa-Bold.ttf') format('truetype');
}

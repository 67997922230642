@import "../../styles/theme";

.coming {
  min-height: 100%;
  min-width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  &-text {
    font-size: calc(1.5rem + 3vw);
    height: calc(2rem + 3vw);
    color: var(--text-color);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (min-width: 576px) {
      font-size: calc(2rem + 3vw);
      height: calc(3rem + 3vw);
    }

    @media (min-width: 768px) {
      font-size: 6rem;
      height: 7rem;
    }

    @media (min-width: 992px) {
      font-size: 8rem;
      height: 9rem;
    }
  }
}
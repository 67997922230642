@import './colors';
@import './variables';
@import './mixins';

@mixin light-mode {
  --theme-icon: url('../assets/Icons/crescent-moon-black.svg');
  --bg-color: #{$white};
  --bg-color-contrast: #{$black};
  --text-color: #{$black};
  --text-color-contrast: #{$white};
  --text-color-stroke-color: #{$black};
}

@mixin dark-mode {
  --theme-icon: url('../assets/Icons/shining-sun-white.svg');
  --bg-color: #{$black};
  --bg-color-contrast: #{$white};
  --text-color: #{$white};
  --text-color-contrast: #{$black};
  --text-color-stroke-color: #{$white};
}

body {
  @include dark-mode;
  &.inverted {
    @include light-mode;
  }
  @media (prefers-color-scheme: dark) {
    @include dark-mode;
    &.inverted {
      @include light-mode;
    }
  }
}
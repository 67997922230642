@import '../../styles/theme';

$spacing: 20px;
$line-width: 3px;

.outline {
  height: 100%;
  width: 100%;
  background-color: var(--bg-color);
  padding: calc($spacing + $line-width);
  position: relative;

  &-outlet {
    height: 100%;
    width: 100%;
    overflow: auto;
  }
}

.line {
  position: absolute;
  background-color: var(--bg-color-contrast);

  &-tl {
    top: $spacing;
    left: $spacing;
  }

  &-br {
    bottom: $spacing;
    right: $spacing;
  }

  &-h {
    height: $line-width;
    width: 65%;
    max-width: calc(100% - 7 * $spacing);
  }

  &-v {
    width: $line-width;
    height: 85%;
    max-height: calc(100% - 4 * $spacing);
  }
}

.licence {
  position: absolute;
  bottom: calc($spacing - $line-width);
  left: calc($spacing - $line-width);
  font-size: calc($spacing - $line-width);
  text-transform: uppercase;
  color: var(--text-color);
  min-width: calc(5 * $spacing);
  min-height: calc(1 * $spacing);

  &-copyright {
    font-family: $comfortaa;
  }

  &-year {
    font-family: $orbitron;
  }
}

.lang-theme {
  position: absolute;
  top: calc($spacing - $line-width);
  right: calc($spacing - $line-width);
  color: var(--text-color);
  min-width: calc(5 * $spacing);
  min-height: calc(1 * $spacing);
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;

  .lang {
    cursor: pointer;
    font-size: calc($spacing - $line-width);
    text-transform: uppercase;
    text-align: right;
    display: flex;
    align-items: center;
  }

  .theme {
    cursor: pointer;
    background-image: var(--theme-icon);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: $spacing;
    width: $spacing;
  }
}
